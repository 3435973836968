<template>
  <v-dialog v-model="importDialog" max-width="500" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" depressed small color="primary" class="ml-2">
        <v-icon size="20" class="mr-1">mdi-database-import-outline</v-icon>
        {{ importText }}
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title class="pl-0">Importar elementos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-4">
        <v-row dense class="flex-column">
          <import-step-one
            :download-import-excel-template-actions="crudActions.downloadImportExcelTemplate"
          />
          <import-step-two
            v-if="importDialog"
            :import-dialog="importDialog"
            :getUploadingStatusAction="crudActions.getUploadingStatus"
            :getImportPath="crudActions.getImportPath"
            @uploadedFile="closeAndRefresh"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    importStepOne: () => import("@/components/crud-md/actions/import-items/StepOne"),
    importStepTwo: () => import("@/components/crud-md/actions/import-items/step-two"),
  },
  props: {
    importText: { type: String, required: true },
    crudActions: { type: Object, required: true },
  },
  data: () => ({
    importDialog: null,
  }),
  methods: {
    closeDialog() {
      this.importDialog = false;
    },
    closeAndRefresh() {
      this.$root.$emit("refreshItems");
      this.closeDialog();
    },
  },
};
</script>

<style scoped></style>
